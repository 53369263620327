import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { PVMCE } from "utility/variables";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    RenderCurrency,
    RenderDateTime,
} from "Utils/renderField/renderReadField";
import { PrintContainer, PrintComponent } from "Utils/Print";
import { Link } from "react-router-dom";

import "./impresion_cierre.css";

class Impresion extends Component {
    static propTypes = {
        cierre: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.props.get_apertura(this.props.match.params.id, true);
    }

    render() {
        // state

        // bind
        const {
            apertura: ultimo_cierre,
            loader,
            loader_print,
            ruta,
            tipo_punto_venta,
            print_state,
            utiliza_fel,
        } = this.props;
        const usuario = ultimo_cierre.usuario ? ultimo_cierre.usuario : {};
        const caja = ultimo_cierre.caja ? ultimo_cierre.caja : {};
        const movimientos = ultimo_cierre.movimientos
            ? ultimo_cierre.movimientos
            : [];
        const ruta_punto_venta =
            tipo_punto_venta == PVMCE
                ? "/punto_de_venta/caja"
                : "/punto_de_venta";

        //  TOTALES PARA EL RESUMEN DE CAJA
        let total_ventas_brutas = 0;
        let total_facturacion_venta = 0;
        //  DETALLE CAJA
        let total_tarjeta = 0;
        let total_efectivo = 0;
        let total_cheque = 0;
        let total_deposito = 0;
        let total_nota_credito = 0;
        let total_retencion_iva = 0;
        let total_exencion_iva = 0;
        let total_general_detalle_caja = 0;
        //  RESULTADO
        let total_resultado = 0;
        if (_.isEmpty(ultimo_cierre) == false) {
            const total_ventas = ultimo_cierre.total_ventas
                ? ultimo_cierre.total_ventas
                : 0;
            const monto_total_ventas_anuladas = ultimo_cierre.monto_total_ventas_anuladas
                ? ultimo_cierre.monto_total_ventas_anuladas
                : 0;
            const total_egreso_efectivo = ultimo_cierre.total_egreso_efectivo
                ? ultimo_cierre.total_egreso_efectivo
                : 0;
            const total_venta_credito = ultimo_cierre.total_venta_credito
                ? ultimo_cierre.total_venta_credito
                : 0;

            const total_ingreso_efectivo = ultimo_cierre.total_ingreso_efectivo
                ? ultimo_cierre.total_ingreso_efectivo
                : 0;

            //  DETALLE CAJA
            const detalle_total_ventas = ultimo_cierre.detalle_total_ventas
                ? ultimo_cierre.detalle_total_ventas
                : {};
            const total_apertura = ultimo_cierre.total_apertura
                ? ultimo_cierre.total_apertura
                : 0;
            const total_ventas_tarjeta = detalle_total_ventas.total_tarjeta
                ? detalle_total_ventas.total_tarjeta
                : 0;
            const total_ventas_efectivo = detalle_total_ventas.total_efectivo
                ? detalle_total_ventas.total_efectivo
                : 0;
            const total_ventas_cheque = detalle_total_ventas.total_cheque
                ? detalle_total_ventas.total_cheque
                : 0;
            const total_ventas_deposito = detalle_total_ventas.total_deposito
                ? detalle_total_ventas.total_deposito
                : 0;
            const total_ventas_nota_credito = detalle_total_ventas.total_nota_credito
                ? detalle_total_ventas.total_nota_credito
                : 0;
            const total_ventas_retencion_iva = detalle_total_ventas.total_retencion_iva
                ? detalle_total_ventas.total_retencion_iva
                : 0;
            const total_ventas_exencion_iva = detalle_total_ventas.total_exencion_iva
                ? detalle_total_ventas.total_exencion_iva
                : 0;

            //  ABONOS
            const abonos = ultimo_cierre.abonos ? ultimo_cierre.abonos : {};
            const total_abonos = abonos.total ? abonos.total : 0;
            const total_abono_tarjeta = abonos.total_tarjeta
                ? abonos.total_tarjeta
                : 0;
            const total_abono_efectivo = abonos.total_efectivo
                ? abonos.total_efectivo
                : 0;
            const total_abono_cheque = abonos.total_cheque
                ? abonos.total_cheque
                : 0;
            const total_abono_deposito = abonos.total_deposito
                ? abonos.total_deposito
                : 0;
            const total_abono_nota_credito = abonos.total_nota_credito
                ? abonos.total_nota_credito
                : 0;
            const total_abono_retencion_iva = abonos.total_retencion_iva
                ? abonos.total_retencion_iva
                : 0;
            const total_abono_exencion_iva = abonos.total_exencion_iva
                ? abonos.total_exencion_iva
                : 0;

            total_ventas_brutas =
                total_ventas +
                monto_total_ventas_anuladas +
                total_venta_credito;
            total_facturacion_venta =
                total_ventas_brutas -
                monto_total_ventas_anuladas -
                total_venta_credito;
            total_tarjeta = total_ventas_tarjeta + total_abono_tarjeta;
            total_efectivo =
                total_apertura + total_ventas_efectivo + total_abono_efectivo;
            total_cheque = total_ventas_cheque + total_abono_cheque;
            total_deposito = total_ventas_deposito + total_abono_deposito;
            total_nota_credito =
                total_ventas_nota_credito + total_abono_nota_credito;
            total_retencion_iva =
                total_ventas_retencion_iva + total_abono_retencion_iva;
            total_exencion_iva =
                total_ventas_exencion_iva + total_abono_exencion_iva;

            total_general_detalle_caja =
                total_efectivo +
                total_tarjeta +
                total_cheque +
                total_deposito +
                total_retencion_iva +
                total_exencion_iva -
                total_egreso_efectivo +
                total_ingreso_efectivo;
            if (utiliza_fel) {
                total_general_detalle_caja += total_nota_credito;
            }

            //  RESULTADO
            total_resultado =
                total_general_detalle_caja -
                total_facturacion_venta -
                total_abonos -
                total_apertura;
        }
        return (
            <LoadMask loading={loader_print} light blur radius>
                {print_state.flag && <PrintComponent />}
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 no-print-padding">
                        <div className="row">
                            <div className="col-12 mb1">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.print("resumenCierreCaja");
                                    }}
                                    className="btn btn-secondary mt-3"
                                    type={"button"}
                                >
                                    <em className="fa fa-print" /> Imprimir
                                </button>
                            </div>
                        </div>
                        <PrintContainer name="resumenCierreCaja">
                            <div className="print-shadow">
                                <div
                                    className="encabezado-cierre"
                                    style={{ border: "0" }}
                                >
                                    <b className="titulo-cierre">
                                        Resumen de cierre
                                    </b>
                                    <div>
                                        <b>Usuario:</b>
                                        <span className="pull-right">
                                            {usuario.first_name}{" "}
                                            {usuario.last_name}
                                        </span>
                                    </div>
                                    <div>
                                        <b>Sucursal:</b>
                                        <span className="pull-right">
                                            {caja.nombre_sucursal}
                                        </span>
                                    </div>
                                    <div>
                                        <b>Caja:</b>
                                        <span className="pull-right">
                                            {caja.nombre}
                                        </span>
                                    </div>
                                    <div>
                                        <b>Fecha de Apertura:</b>
                                    </div>
                                    <div>
                                        <RenderDateTime
                                            className="text-right"
                                            value={ultimo_cierre.creado}
                                        />
                                    </div>
                                    <div>
                                        <b>Fecha de Cierre:</b>
                                    </div>
                                    <div>
                                        <RenderDateTime
                                            className="text-right"
                                            value={ultimo_cierre.fecha_cierre}
                                        />
                                    </div>
                                    <div>
                                        <b>Apertura:</b>
                                        <span className="pull-right">
                                            <RenderCurrency
                                                value={
                                                    ultimo_cierre.total_apertura
                                                }
                                                className={"pull-right"}
                                            />{" "}
                                        </span>
                                    </div>
                                </div>
                                <br />
                                <div className="resumen-cierre">
                                    <div className="cierre-elemento">
                                        <b>
                                            Detalle de{" "}
                                            {utiliza_fel
                                                ? "facturación"
                                                : "venta"}
                                        </b>
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>Ventas brutas:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_ventas_brutas}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>
                                            (-) Ventas anuladas (
                                            {ultimo_cierre.total_ventas_anuladas
                                                ? ultimo_cierre.total_ventas_anuladas
                                                : 0}
                                            ):
                                        </b>
                                        &nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={
                                                ultimo_cierre.monto_total_ventas_anuladas
                                                    ? ultimo_cierre.monto_total_ventas_anuladas
                                                    : 0
                                            }
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(-) Créditos:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={
                                                ultimo_cierre.total_venta_credito
                                                    ? ultimo_cierre.total_venta_credito
                                                    : 0
                                            }
                                            className={"pull-right"}
                                        />
                                    </div>
                                    {/* <div className="cierre-elemento pl-3">
                                        <b>(-) Egreso en efectivo:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={
                                                ultimo_cierre.total_egreso_efectivo
                                                    ? ultimo_cierre.total_egreso_efectivo
                                                    : 0
                                            }
                                            className={"pull-right"}
                                        />
                                    </div> */}
                                    <div className="cierre-elemento pl-3">
                                        <b>
                                            Total de{" "}
                                            {utiliza_fel
                                                ? "facturación"
                                                : "venta"}
                                            :
                                        </b>
                                        &nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_facturacion_venta}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>Total de abonos:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={
                                                ultimo_cierre.abonos &&
                                                ultimo_cierre.abonos.total
                                                    ? ultimo_cierre.abonos.total
                                                    : 0
                                            }
                                            className={"pull-right"}
                                        />
                                    </div>

                                    {/* DETALLE CAJA */}
                                    <div className="cierre-elemento">
                                        <b>Detalle de caja</b>
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(+) Efectivo:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_efectivo}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(-) Egreso en efectivo:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={
                                                ultimo_cierre.total_egreso_efectivo
                                                    ? ultimo_cierre.total_egreso_efectivo
                                                    : 0
                                            }
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(+) Tarjeta de crédito:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_tarjeta}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(+) Cheque:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_cheque}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(+) Depósito:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_deposito}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    {utiliza_fel && (
                                        <div className="cierre-elemento pl-3">
                                            <b>(+) Nota de crédito:</b>&nbsp;
                                            <span className="cierre-subrayo" />
                                            &nbsp;
                                            <RenderCurrency
                                                value={total_nota_credito}
                                                className={"pull-right"}
                                            />
                                        </div>
                                    )}
                                    <div className="cierre-elemento pl-3">
                                        <b>(+) Retención de IVA:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_retencion_iva}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>(+) Exención de IVA:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_exencion_iva}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    <div className="cierre-elemento pl-3">
                                        <b>Total general:</b>&nbsp;
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_general_detalle_caja}
                                            className={"pull-right"}
                                        />
                                    </div>
                                    {/* <div className="cierre-elemento py-3">
                                        <b>Resultado (+)(-):</b>
                                        <span className="cierre-subrayo" />
                                        &nbsp;
                                        <RenderCurrency
                                            value={total_resultado}
                                            className={"pull-right"}
                                        />
                                    </div> */}
                                    {/* MOVIMIENTOS INGRESO Y EGRESO CAJA */}
                                    {ultimo_cierre.movimientos_ingresos &&
                                    ultimo_cierre.movimientos_ingresos
                                        .length ? (
                                        <div>
                                            <div className="cierre-elemento">
                                                <b>Movimientos ingresos:</b>
                                            </div>
                                            {ultimo_cierre.movimientos_ingresos &&
                                                ultimo_cierre.movimientos_ingresos.map(
                                                    (movimiento, index) => {
                                                        return (
                                                            <div
                                                                className={`detalle-movimiento-cierre cierre-elemento ${
                                                                    index == 0
                                                                        ? "mt-1"
                                                                        : ""
                                                                }`}
                                                                key={
                                                                    movimiento.id
                                                                }
                                                            >
                                                                <span>
                                                                    {
                                                                        movimiento.concepto
                                                                    }
                                                                    &nbsp;
                                                                </span>
                                                                <span className="cierre-subrayo" />
                                                                &nbsp;
                                                                <RenderCurrency
                                                                    value={
                                                                        movimiento.monto_efectivo
                                                                    }
                                                                    className={
                                                                        "pull-right"
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    ) : null}
                                    {ultimo_cierre.movimientos_egreso &&
                                    ultimo_cierre.movimientos_egreso.length ? (
                                        <div>
                                            <div className="cierre-elemento">
                                                <b>Movimientos egresos:</b>
                                            </div>
                                            {ultimo_cierre.movimientos_egresos &&
                                                ultimo_cierre.movimientos_egresos.map(
                                                    (movimiento, index) => {
                                                        return (
                                                            <div
                                                                className={`detalle-movimiento-cierre cierre-elemento ${
                                                                    index == 0
                                                                        ? "mt-1"
                                                                        : ""
                                                                }`}
                                                                key={
                                                                    movimiento.id
                                                                }
                                                            >
                                                                <span>
                                                                    {
                                                                        movimiento.concepto
                                                                    }
                                                                    &nbsp;{" "}
                                                                </span>
                                                                <span className="cierre-subrayo" />
                                                                &nbsp;
                                                                <span>
                                                                    -&nbsp;
                                                                </span>
                                                                <RenderCurrency
                                                                    value={
                                                                        movimiento.monto_efectivo
                                                                    }
                                                                    className={
                                                                        "pull-right"
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    ) : null}
                                </div>
                                {/* <div>
                                    <div className="mt1">
                                        <b>Total Efectivo en Caja:</b>
                                        <RenderCurrency
                                            value={ultimo_cierre.total_actual}
                                            className={"pull-right"}
                                        />
                                    </div>
                                </div> */}
                                {ultimo_cierre.concepto_cierre && (
                                    <div>
                                        <div>
                                            <b>Concepto de cierre:</b>
                                            <span>
                                                &nbsp;
                                                {ultimo_cierre.concepto_cierre}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </PrintContainer>
                    </div>
                    <div className="d-flex justify-content-center flex-row w-100 mt-3">
                        <Link
                            className="btn btn-tertiary m-1"
                            to={
                                ruta.includes("reporte")
                                    ? "/reporte_apertura_cierre_caja"
                                    : ruta_punto_venta
                            }
                        >
                            <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                            />{" "}
                            Regresar
                        </Link>
                    </div>
                </div>
            </LoadMask>
        );
    }
}

export default Impresion;
